import {History} from 'history'
import {WIDGET_TYPE, LIST_LAYOUT, WIDGET_LAYOUT} from '@wix/wix-events-commons-statics'
import {INNER_PAGE, PAGE, TAB} from '../../../../commons/constants/navigation'
import {BASE_PATH} from '../constants'

let history: History

export interface SettingsNavigationInfo {
  tab?: TAB
  page?: PAGE
  innerPage?: INNER_PAGE | string
  widgetType?: WIDGET_TYPE
  widgetLayout?: LIST_LAYOUT | WIDGET_LAYOUT
  subPage?: string
}

export const setHistory = (objHistory: History) => (history = objHistory)

export const getHistory = (): History => history

export const buildPath = ({tab, page, innerPage, widgetType, widgetLayout}: SettingsNavigationInfo) => {
  const currentNavigationInfo = getNavigationInfo()
  const pathParts = []

  pathParts.push((page || tab) ?? currentNavigationInfo.tab)
  pathParts.push(widgetType ?? currentNavigationInfo.widgetType ?? WIDGET_TYPE.LIST)
  pathParts.push(widgetLayout ?? currentNavigationInfo.widgetLayout ?? LIST_LAYOUT.LIST)

  if (innerPage) {
    pathParts.push(innerPage)
  }

  return `/${pathParts.join('/')}`
}

export const getNavigationInfo = (disableDefaults = false): SettingsNavigationInfo => {
  const defaultTab = disableDefaults ? null : TAB.MAIN

  const path = getHistory().location.pathname

  const matches = path.match(/\/[^/]+/g)
  const parts = matches ? matches.map(part => part.substr(1)) : []

  const [tabOrPage, widgetType, widgetLayout, innerPage, subPage] = parts as [
    TAB | PAGE,
    WIDGET_TYPE,
    WIDGET_LAYOUT,
    INNER_PAGE,
    string,
  ]

  const tab = tabOrPage as TAB
  const page = tabOrPage as PAGE

  return {
    page: Object.values(PAGE).includes(page) ? page : undefined,
    tab: Object.values(TAB).includes(tab) ? tab : defaultTab,
    widgetType: widgetType && Number(widgetType),
    widgetLayout: widgetLayout && Number(widgetLayout),
    innerPage,
    subPage,
  }
}

export const isValidTab = (tab: string) => Object.values(TAB).includes(tab as TAB)

export const isEmptyPath = () => getHistory().location.pathname === BASE_PATH

export const setInitialTab = (initialTab: string) => {
  history.push(buildPath({tab: isValidTab(initialTab) ? (initialTab as TAB) : TAB.MAIN}))
}
